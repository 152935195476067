<template>
    <div class="contain-box" >
        <div class="card-header title">
            <h3>
                {{$t("top_page.inquiry.page_title")}}
            </h3>
        </div>
        <div class="container card-profile">
            <div class="card-body p-0 inquiry">
                <FormView @submit="onSubmit">
                    <div class="row " >
                        <div class="col-lg-12">
                            <div class="group-frm">
                               
                                <div class="row mb-0 ">
                                    <div class="col-md-3">
                                        <br>
                                        <label>{{ $t('top_page.inquiry.type') }} </label>
                                        <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-md-8">
                                        <Radio
                                            class="type-list"
                                            :options="types"
                                            name="type"
                                            v-model="inquiry.type"
                                            :preLine="true"
                                            style="accent-color: #1EAA39;"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="group-frm">
                               
                                <InlineInput
                                    :label="$t('top_page.inquiry.name')"
                                    :placeholder="$t('top_page.inquiry.name')"
                                    name="name"
                                    rules="required|max:250"
                                    v-model="inquiry.name"
                                />
                            </div>
                            <div class="group-frm">
                               
                                <InlineInput
                                    :label="$t('top_page.inquiry.email')"
                                    :placeholder="$t('top_page.inquiry.email')"
                                    name="email"
                                    rules="required|email|max:250"
                                    v-model="inquiry.email"
                                />
                            </div>
                            <div class="group-frm">
                                
                                <InlineTextArea
                                        :label="$t('top_page.inquiry.detail')"
                                        :placeholder="$t('top_page.inquiry.detail_placeholder')"
                                        required
                                        name="detail"
                                        v-model="inquiry.detail"
                                    />
                            </div>
                            <div class="group-frm pb-0 " style="padding-top:30px;">
                               
                                <div class="text-center">
                                    <button 
                                    class="btn btn-success " 
                                    type="submit">
                                    {{$t('top_page.inquiry.submit')}}
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </FormView>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "SubmitForm",
    data() {
        return {
            inquiry: {
                type: "",
                name: "",
                email: "",
                detail: ""
            },
            types: [],
        };
    },
    props: {
        oldInquiry: {}
    },
    async created() {
        this.success = 0;
        await this.getInquiryTypes({
            cb:(response) => {
                if(response.data){
                    this.types = response.data;
                    this.inquiry.type = this.types[0]['value'];
                }
            }});
        if(Object.keys(this.oldInquiry).length){
            this.inquiry = this.oldInquiry;
        }
        this.loadUserInfo(this.user);
    },
    computed: {
        ...mapGetters({
            user: "member/getUser"
        })
    },
    methods: {
        ...mapActions({
            getInquiryTypes: "common/getInquiryTypes",
        }),
        onSubmit(){
            this.$emit("change", this.inquiry);
        },
        loadUserInfo(data){
            if(data.info){
                this.inquiry = {
                    ...this.inquiry,
                    email: data.info.email,
                    name: data.info.name
                }
            }
        }
    },
    watch: {
        user(data){
           this.loadUserInfo(data);
        }
    }
}
</script>
