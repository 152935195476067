<template>
    <div class="main">
        <div class="inquiry-admin">
            <div v-if="step == 3">
                <SubmitCompleted />
            </div>
            <div v-else-if="step == 2">
                <ViewForm @change="handleStep" :inquiry="inquiry"/>
            </div>
            <div v-else>
                <SubmitForm @change="handleConfirm" :oldInquiry="inquiry"/>
            </div>
        </div>
        <MetaTags/>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import SubmitForm from './components/submit-form.vue';
import SubmitCompleted from './components/submit-completed.vue';
import ViewForm from './components/view-form.vue';
export default {
    name: "InquiryToAdmin",
    data() {
        return {
            inquiry: { },
            step: 1,
        };
    },
    methods: {
        ...mapActions({
            inquiryToAdmin: "common/inquiryToAdmin",
        }),
        handleConfirm(data){
            this.inquiry = data;
            this.handleStep(2);
        },
        handleStep(step){
            this.step = step;
        }
    },
    components: { 
        SubmitForm,
        SubmitCompleted,
        ViewForm
     }
}
</script>
<style scoped>
.card-body{
    min-height: 75vh;
}
</style>